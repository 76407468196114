<template>
    <div>

        <div class="header">
            <div class="headcon">
                <span @click="goHomeIndex">
                    <icon name="iconshouye" size="15px" color="#ddd" class="btn"></icon>&nbsp;驼鱼商城首页
                </span>
                <button @click="loginOut" v-if="loginStatus">退出</button>
                <p v-if="loginStatus">欢迎您 <b style="color: red">{{userName}}</b></p>
                <p @click="loginPop" v-if="!loginStatus">你好，请登录</p>

            </div>
            <mySlideBar></mySlideBar>


        </div>
        <div class="bg_bor">
            <div class="search_box">
                <span @click="goHomeIndex"><img src="../assets/images/tuotu.png" alt=""></span>
                <div class="searchcon">
                    <div>
                        <input type="text" v-model="name" placeholder="请输入搜索的关键字">
                        <button @click="goSearchList">
                            <icon name="iconsousuo" size="20px" color="#fff"></icon>
                        </button>
                    </div>
                    <!-- 暂时不做-->
                    <!--                    <ul>-->
                    <!--                        <li :class="{active:checkIndex == index}" v-for="(item,index) in hotWords" @click="clikcHotWords(item.name,index)" >{{item.name}}</li>-->
                    <!--                    </ul>-->
                </div>
                <dl>
                    <dd class="active" @click="showClass =!showClass;checkArea=0">全部分类</dd>
                    <dd :class="{areaActive:checkArea == item.id}" v-for="item in areaKey" @click="goArea(item.id)">
                        {{item.name}}
                    </dd>
                </dl>
            </div>
        </div>
        <!-- 提示 -->
        <toast ref="toast"></toast>



        <!--        登录弹框 end -->
        <!--        全部分类 begin -->
        <div>
            <div class="pr classBox" v-show="showClass">
                <div class="goodsClass clearfix pr" v-on:mouseleave="showClass = false">
                    <ul class="fl " id="classHeigh">
                        <li v-for="(item,index) in getBrandGoodss" :key="index"
                            v-on:mouseover="showClassTwo(index,item.cate_id)"><i></i>{{item.cate_name}} <icon
                                name="iconyou-" size="25px" color="#999999"></icon>
                        </li>
                    </ul>
                    <div class="fr twoClass">
                        <dl class="clearfix">
                            <dd class="clearfix" v-for="item in towClass" @click="goList(item.cate_id)">
                                <img data-v-ac4526a4="" :src="imgPath+item.img_url" alt="">
                                <p>{{item.cate_name}}</p>
                            </dd>
                        </dl>
                    </div>
                </div>

            </div>


        </div>
        <!--        全部分类 end -->
        <router-view></router-view>
    </div>

</template>

<script>
    import {getBrandGoods} from '@/global/https'
    import icon from '@/components/icon/iconfont.vue'
    import slideBar from '@/components/slideBar' //工具栏
    import toast from "@/components/toast.vue"
    import {login} from '@/views/homePage/home' // 获取登录
    import {cateGory} from '@/views/filtrateList/filtrate'// 获取分类
    // import Vue from 'vue'
    export default {

        components: {
            mySlideBar: slideBar,
            icon,
            toast
        },
        data() {
            return {
                name: '',//搜索词
                checkIndex: 10,//选中热搜词 index 默认没有
                showClass: false,
                userName: '',
                loginStatus: false, // 是否登录
                isShowPop: false, // 是否显示登录弹框
                loginMsg: '', // 登录返回错误信息提示
                // 登录
                loginInfo: {
                    user_name: '', // 用户名
                    password: '' // 密码
                },
                hotWords: [
                    {name: '羊毛'},
                    {name: '5G新机推荐'},
                    {name: '5G新机推荐'},
                    {name: '5G新机推荐'},
                    {name: '5G新机推荐'}
                ],

                getBrandGoodss: [],//分类
                towClass: [],//二级分类
                oneClassId: 0,//选中一级分类id
                checkArea: 0, //选中专区id  添加样式变红色
                areaKey: [
                    {
                        name: '99专区',
                        id: 1,
                    },
                    {
                        name: '新品首发',
                        id: 3
                    },
                    {
                        name: '销量TOP榜',
                        id: 4,
                    },
                    {
                        name: '品牌闪购',
                        id: 2
                    },
                    {
                        name: '买手推荐',
                        id: 6
                    },
                    {
                        name: '爆品推荐',
                        id: 12
                    },
                ]
            }

        },
        watch: {
            // 监听跳转专区
            '$route': function (val) {
                this.checkIndex = 10;
                switch (val.query.type) {
                    case 4:
                        this.checkArea = 4;
                        break;
                    case 6:
                        this.checkArea = 6;
                        break;
                    case 1:
                        this.checkArea = 1;
                        break;
                    case 2:
                        this.checkArea = 2;
                        break;
                    case 3:
                        this.checkArea = 3;
                        break;
                    case 12:
                        this.checkArea = 12;
                        break;
                    default:
                        this.checkArea = 0;
                };
                if (val.query.name) {
                    this.name = val.query.name
                } else {
                    this.name = '';
                }

            }
        },
        mounted() {

            let cookie = JSON.parse(localStorage.getItem('cookie') || '{}')
            this.userName = cookie.user_name;
            // 判断是否登录
            if (cookie.tokenId) {
                this.loginStatus = true
            }
            if (this.$route.query.name) {
                this.name = this.$route.query.name;
            }
            this.getBrandGoods();
            this.goArea(this.$route.query.type, 1);
        },
        methods: {
            // 获取分类
            async getBrandGoods() {
                let {code, msg, total, pageNum, rows} = await getBrandGoods({});
                this.getBrandGoodss = rows;
                this.showClassTwo(0, this.getBrandGoodss[0].cate_id);
                // let { message, code, data, status } = await cateGory({});
                // if (status == 200) {
                //     this.cateGorys= data;
                //     this.showClassTwo(0,this.cateGorys[0].cate_id);
                // }
            },
            // 登录接口
            async login() {
                let params = this.loginInfo;
                if (params.user_name == "") {
                    this.$refs.toast.showToast("请输入账号~~");
                } else if (params.password == "") {
                    this.$refs.toast.showToast("请输入密码~~");
                } else {
                    let {message, code, data, status} = await login(params);
                    if (status == 200) {
                        // 设置cookie
                        let cookie = localStorage.getItem("cookie") || {};
                        cookie = JSON.parse(decodeURIComponent(decodeURIComponent(data.psw)));
                        localStorage.setItem("cookie", JSON.stringify(cookie));
                        this.loginInfo.user_name = ''
                        this.loginInfo.password = ''
                        // 登录状态改为true
                        this.$refs.toast.showToast("登录成功");
                        // 隐藏弹框
                        this.isShowPop = false;
                        location.reload();
                    } else {
                        this.$refs.toast.showToast(message);
                    }
                }

            },
            // 退出登录
            loginOut() {
                // 清除本地存储
                localStorage.clear();
                // 登录状态改为false
                this.loginStatus = false

                this.$refs.toast.showToast("退出成功");
                location.reload();
            },
            // 是否显示弹框
            loginCancle() {
                this.$store.commit('changeShowLogin', 0);
                this.isShowPop = false;

            },
            loginPop() {
                this.isShowPop = true;
                this.$store.commit('changeShowLogin', 1);
            },
            // 跳转专区
            goArea(id, isReload) {
                this.checkArea = id;
                if (isReload) {
                    return false;
                } else {
                    if (id == 2) {
                        this.$router.push({path: '/brand', query: {type: id}})
                        return false;
                    }
                    this.$router.push({path: '/goodsList', query: {type: id}})
                }

            },
            //跳转首页
            goHomeIndex() {
                this.$router.push({path: '/index'})
            },

            // 获取分类相关
            showClassTwo(index, id) {
                this.oneClassId = id;
                this.towClass = this.getBrandGoodss[index].next;
            },
            // 获取分类相关
            goList(twoId) {
                this.$router.push({path: '/scarchList', query: {cateId: this.oneClassId, belowID: twoId}});
                this.showClass = false;
                if (this.$route.path == '/scarchList') {
                    window.location.reload();
                }
            },
            //点击热搜词
            clikcHotWords(name, index) {
                this.name = name;
                this.checkIndex = index;
                this.$router.push({path: '/scarchList', query: {name: this.name}})
            },
            //去搜索列表
            goSearchList() {
                this.$router.push({path: '/scarchList', query: {name: this.name}})
            }
        }
    }
</script>
<style lang="less" scoped>
    .header {
        position: fixed;
        top: 0;
        left: 0;
        background: #F7F7F7;
        width: 100%;
        height: 32px;
        line-height: 32px;
        z-index: 9999;

        .headcon {
            width: 1200px;
            height: 32px;
            margin: 0 auto;
            font-size: 14px;
            color: #999999;

            button {
                float: right;
                background: none;
                outline: none;
                border: none;
                font-size: 14px;
                line-height: 32px;
                color: #999999;
                margin-left: 20px;
                cursor: pointer;
            }

            span {
                float: left;
                cursor: pointer;
            }

            span:hover,
            span:hover .btn {
                color: #EC1430;
            }

            p {
                float: right;
                color: #999;
                cursor: pointer;
            }

            p:hover,
            button:hover {
                color: #EC1430;
            }
        }


    }

    @width: 1200px;

    .search_box {
        width: @width;
        height: 206px;
        margin: 0 auto;

        span {
            float: left;
            height: 160px;
        }

        img {
            width: 227px;
            height: 85px;
            margin-top: 30px;
            float: left;
            cursor: pointer;
        }

        .searchcon {
            float: left;
            width: 742px;
            height: 85px;
            margin-top: 30px;
            margin-left: 80px;
            padding-top: 30px;

            div {
                position: relative;
                width: 742px;
                height: 36px;
                border: 2px solid #EEEEEE;
            }

            input {
                width: 662px;
                height: 36px;
                line-height: 36px;
                float: left;
                padding-left: 10px;
            }

            button {
                width: 70px;
                height: 38px;
                line-height: 38px;
                color: #fff;
                background: #EC1430;
                border: none;
                position: absolute;
                top: -1px;
                right: -1px;
                outline: none;
                cursor: pointer;
            }

            ul {
                width: 742px;
                height: 22px;
                line-height: 22px;
                padding-top: 10px;
                display: block;

                li {
                    float: left;
                    color: #999999;
                    font-size: 14px;
                    padding-right: 10px;
                    cursor: pointer;
                }

                li:hover,
                li.active {
                    color: #EC1430
                }


            }

        }

        dl {
            float: left;
            width: 1200px;
            height: 44px;

            dd {
                width: 148px;
                height: 44px;
                line-height: 44px;
                float: left;
                color: #333333;
                font-size: 16px;
                cursor: pointer;

                &.areaActive {
                    color: #EC1430;
                    font-weight: bold;
                }
            }

            dd.active {
                color: #fff;
                background: url("../assets/images/bg_all_Classification.png") no-repeat center center;
            }
        }
    }

    /*   登录  */
    .login_bg {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .6);
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 111;

        .login_box {
            width: 490px;
            height: 340px;
            background: #fff;
            position: fixed;
            top: 50%;
            left: 50%;
            margin-left: -245px;
            margin-top: -170px;
            border-radius: 10px;

            button {
                width: 180px;
                height: 60px;
                border: 1px solid rgba(203, 203, 203, 1);
                border-radius: 4px;
                color: #CBCBCB;
                font-size: 20px;
                background: none;
                margin-top: 40px;
                margin-left: 49px;
                cursor: pointer;
                outline: none;
            }

            button.red {
                background: #E60026;
                color: #fff !important;
                margin-right: 49px;
                margin-left: 0;
                border: 1px solid #E60026;
            }

            .showtip {
                height: 32px;
                line-height: 32px;
                padding: 0 20px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: rgba(0, 0, 0, .5);
                color: #fff;
                border-radius: 4px;
            }

            ul {
                width: 390px;
                margin: 0 auto;
                padding-top: 50px;

                li {
                    width: 388px;
                    height: 58px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    margin-bottom: 20px;
                    float: left;

                    .btn {
                        float: left;
                        width: 58px;
                        height: 58px;
                        padding-right: 7px;
                        text-align: center;
                        line-height: 58px;
                    }

                    input {
                        width: 300px;
                        height: 58px;
                        float: left;
                        background: none;
                        font-size: 18px;
                    }
                }

            }
        }
    }

    .classBox {
        width: @width;
        margin: 0 auto;
    }

    .goodsClass {
        width: 566px;
        z-index: 9999;
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
        box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.21);

        ul {
            width: 148px;
            background: #fff;
            ;
            box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.21);

            li {
                margin: 0 8px;
                height: 46px;
                line-height: 46px;
                cursor: pointer;
                border-bottom: 1px solid rgba(221, 221, 221, 1);

                &:last-child {
                    border-bottom: 0 none;
                }
            }
        }

        .twoClass {
            width: 390px;
            padding-left: 20px;
            background: #fff;
            padding-top: 20px;

            dd {
                float: left;
                margin-bottom: 65px;

                img {
                    width: 60px;
                    height: 60px;
                    float: left;
                    margin-left: 20px;
                    cursor: pointer;
                }

                p {
                    margin-top: 23px;
                    vertical-align: middle;
                    float: left;
                    margin-left: 39px;
                    cursor: pointer;
                    color: #323232;
                }
            }
        }
    }
</style>
<style>
    .bg_bor {
        width: 100%;
        border-bottom: 2px solid #EC1430;
        overflow: hidden;
        margin-top: 32px;
        background: #fff;
    }
</style>