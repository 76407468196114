<template>
    <div class="toolbar">
        <dl>
            <dd @click="about" class="clearfix">
                <icon name="iconabout" size="26px" color="#fff"></icon>
                <p>联系我们</p>
            </dd>
            <dd @click="feedBack">
                <icon name="iconyoujian" size="26px" color="#fff"></icon>
                <p>意见反馈</p>
            </dd>
            <dd  @click="toTop">
                <icon name="icondingbu" size="26px" color="#fff"></icon>
                <p>返回顶部</p>
            </dd>
        </dl>
    </div>
</template>
<script>
    let timer = null;
    import icon from '@/components/icon/iconfont.vue'
    export default {
        props:{
            step:{   //此数据是控制动画快慢的
                type:Number,
                default:100
            }
        },
        components: {
            icon
        },

        methods:{
            // 返回顶部
            toTop(){
                timer = setInterval(function () {
                    let osTop = document.documentElement.scrollTop || document.body.scrollTop
                    let ispeed = Math.floor(-osTop / 5)
                    document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
                    // this.isTop = true
                    if (osTop === 0) {
                        clearInterval(timer)
                    }
                },30)

            },
            // 反馈意见
            feedBack(){
                this.$router.push({name:'aboutUs',query:{tab:1}})
            },
            about(){
                window.location.href='http://www.daominyoupin.cn/p/contact.html' //跳转到官网
                // this.$router.push({name:'aboutUs',query:{tab:2}})
            }
        }
    }
</script>
<style lang="less" scoped>
    .toolbar{
        width: 100px;
        height: 330px;
        position: fixed;
        bottom: 0px;
        right: 0;
        z-index: 10;
        dl{
            width: 60px;
            dd{
                width: 60px;
                height: 56px;
                background: #454545;
                text-align: center;
                padding-top:4px;
                margin-bottom: 10px;
                cursor: pointer;
                p{
                    float: left;
                    width: 60px;
                    text-align: center;
                    color: #fff;
                    font-size: 12px;
                    height: 12px;
                    line-height: 12px;
                }
            }
            dd:hover,dd.active{
                background: #E60026;
            }
        }
    }
</style>
